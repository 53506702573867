import { css, Global } from '@emotion/react';
import { fontsCss } from './fonts';
import { nProgress } from './moduleCss/nprogress';
import { reactResponsiveModal } from './moduleCss/reactResponsiveModal';

export const globalStyles = (
  <Global
    styles={css`
      ${fontsCss}
      ${nProgress}
      ${reactResponsiveModal}
    `}
  />
);
