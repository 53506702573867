import { colors } from './themeColors';
import { darken } from '@theme-ui/color';

const darkenedMuted = darken(colors.muted, 0.25);

export const text = {
  paragraph: {
    // default
    fontSize: [18],
    paddingBottom: 3,
  },
  normal: {
    // default
    fontSize: [18, 20, 20, 22],
    paddingBottom: 3,
  },
  lead: {
    fontSize: [3, 4],
  },
  footnote: {
    fontSize: 1,
    opacity: 0.5,
  },
  small: {
    fontSize: [12],
    opacity: 0.5,
  },
  caption: {
    fontSize: 2,
    paddingBottom: 3,
  },
  heading: {
    // default
    paddingTop: 3,
    paddingBottom: 3,
  },
  headingSection: {
    paddingTop: 3,
    paddingBottom: 4,
  },
  h1: {
    margin: '0 auto',
    maxWidth: 900,
    textAlign: 'center',
    fontSize: [24, 32, 42, 52],
    fontWeight: 300,
    lineHeight: '110%',
  },
  h2: {
    margin: '0 auto',
    maxWidth: 900,
    textAlign: 'center',
    fontSize: [24, 32, 32, 46],
    lineHeight: '110%',
  },
  h3: {
    margin: 0,
    fontSize: [24, 26, 26, 36],
    fontWeight: 300,
    lineHeight: '115%',
  },
  h4: {
    paddingTop: 3,
    paddingBottom: 3,
  },
  h5: {
    paddingTop: 3,
    paddingBottom: 3,
  },
  footer: {
    fontSize: 1,
    color: darkenedMuted,
    textAlign: 'center',
  },
};
