export const fontsCss = `
@font-face {
  font-family: 'AllianzNeoW01';
  src: url('/fonts/AllianzNeoW01-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'AllianzNeoW01-CondensedBold';
  src: url('/fonts/AllianzNeoW01-CondensedBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AllianzNeoW01';
  src: url('/fonts/AllianzNeoW01-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AllianzNeoW01-Condensed';
  src: url('/fonts/AllianzNeoW01-Condensed.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AllianzNeoW01';
  src: url('/fonts/AllianzNeoW01-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AllianzNeoW01';
  src: url('/fonts/AllianzNeoW01-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'AllianzNeoW01-SemiBoldIt';
  src: url('/fonts/AllianzNeoW01-SemiBoldIt.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'AllianzNeoW01';
  src: url('/fonts/AllianzNeoW01-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'AllianzNeoW01-CondLight';
  src: url('/fonts/AllianzNeoW01-CondLight.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AllianzNeoW01';
  src: url('/fonts/AllianzNeoW01-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AllianzNeoW01';
  src: url('/fonts/AllianzNeoW01-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

`;
