export const reactResponsiveModal = `
.customOverlay {
  background: rgba(255, 255, 255, 0.7);
}
.customModal {
  background: linear-gradient(180deg, #FDFDFD 0%, #EBEBEB 100%);
  max-width: 100%;
  margin: 0;
  width: 100%;
  height: 100%;
}
.react-responsive-modal-closeButton{
  position: fixed;
}
`;
