/* eslint-disable prefer-destructuring */

import { pxToRem } from './themeFunctions';
import { golden } from './themeConstants';

const baseCol = (width: number, cols: number) => {
  return pxToRem((width / golden / 12) * cols);
};

const config: any = {
  breakpoints: ['360px', '720px', '960px', '1140px', '1200px'], // (bootstrap 4)
};

export const mediaQueries = {
  sm: `@media screen and (min-width: ${config.breakpoints[0]})`,
  md: `@media screen and (min-width: ${config.breakpoints[1]})`,
  lg: `@media screen and (min-width: ${config.breakpoints[2]})`,
  xl: `@media screen and (min-width: ${config.breakpoints[3]})`,
};

export { config };
