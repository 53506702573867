import { DefaultSeo } from 'next-seo';
import Router from 'next/router';
import { ThemeProvider } from 'theme-ui';
import { theme } from 'styles/theme';
// import { StoreProvider } from 'store/main';
import { globalStyles } from 'styles/globalStyles';
import Head from 'next/head';
import { IS_IE } from 'helpers/constantsClient';

const onRouteChangeStart = () => {
  //
};

const onRouteChangeComplete = (url: string) => {
  if (window.location.hash) {
    const anchor = window.location.hash;
    window.location.hash = '';
    setTimeout(() => {
      window.location.hash = anchor;
    }, 300);
  }
};

const onRouteChangeError = (error: string) => {
  //
};

Router.events.on('routeChangeStart', onRouteChangeStart);
Router.events.on('routeChangeComplete', onRouteChangeComplete);
Router.events.on('routeChangeError', onRouteChangeError);

const App = function App({ Component, pageProps, initialState }: unknown) {
  if (IS_IE) {
    return (
      <div>
        Diese Seite unterstützt Microsoft Internet Explorer nicht. Bitte verwenden Sie Microsoft
        Edge, Safari, Firefox oder Google Chrome.
      </div>
    );
  }
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
      </Head>
      {/* <StoreProvider snapshot={initialState}> */}
      <ThemeProvider theme={theme}>
        <DefaultSeo title="null" description="null" />
        {globalStyles}
        <Component {...pageProps} />
      </ThemeProvider>
      {/* </StoreProvider> */}
    </>
  );
};

export default App;
