import { images } from './themeImages';
import { menu } from './themeMenu';
import { colors } from './themeColors';
import { config } from './themeConfig';
import { styles } from './styles';
import { text } from './themeText';
import { darken, lighten } from '@theme-ui/color';
import { Theme } from '@theme-ui/css';

export const theme: Theme = {
  ...config,
  images,
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body: 'AllianzNeoW01, Roboto, sans-serif',
    heading: 'inherit',
    monospace: 'Menlo, monospace',
  },
  fontSizes: [12, 14, 16, 18, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 800,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  borderRadius: {
    images: '3px',
    blocks: '3px',
    small: '3px',
    medium: '6px',
    large: '9px',
  },
  colors,
  styles,
  text,
  menu,
  links: {
    negative: {
      color: 'background',
    },
  },
  buttons: {
    primary: {
      color: 'background',
      bg: 'primary',
      boxShadow: `0 2px 6px -1px ${colors.text}`,
      '&:hover': {
        bg: darken(colors.primary, 0.1),
        cursor: 'pointer',
      },
    },
    primarySmall: {
      color: 'background',
      bg: 'primary',
      fontSize: 1,
      py: 1,
      px: 2,
      boxShadow: `0 2px 6px -1px ${colors.text}`,
      '&:hover': {
        bg: darken(colors.primary, 0.1),
        cursor: 'pointer',
      },
    },
    secondary: {
      color: 'background',
      bg: 'secondary',
      '&:hover': {
        bg: darken(colors.secondary, 0.1),
        cursor: 'pointer',
      },
    },
    icon: {
      color: 'inherit',
      bg: 'transparent',
      '&:hover': {
        cursor: 'pointer',
        color: lighten(colors.primary, 0.1),
      },
    },
    iconButton: {
      display: 'flex',
      bg: 'transparent',
      color: colors.primary,
      border: 0,
      margin: 0,
      padding: 0,
      width: '100%',
      justifyContent: 'flex-start',
      alignItems: 'center',
      '&:hover': {
        cursor: 'pointer',
        color: lighten(colors.primary, 0.1),
      },
    },
  },
  easing: {
    easeInQuart: [0.895, 0.03, 0.685, 0.22],
    easeOutQuart: [0.165, 0.84, 0.44, 1.0],
  },
};
