export const images = {
  fullWidth: {
    ratio: 16 / 9,
  },
  landscape: {
    ratio: 4 / 3,
  },
  portrait: {
    ratio: 5 / 6,
  },
  square: {
    ratio: 1,
  },
  card: {
    ratio: 4 / 3,
  },
};
