const aHoverUnderline = {
  'a, a:link': {
    textDecoration: 'none',
  },
  'a:hover, a:active': {
    textDecoration: 'underline',
  },
};

export const menu = {
  horizontalItem: {
    inactive: {
      fontSize: 3,
      padding: 2,
      fontWeight: 'body',
      color: 'text',
      ...aHoverUnderline,
    },
    active: {
      fontSize: 3,
      padding: 2,
      fontWeight: 'bold',
      color: 'text',
      ...aHoverUnderline,
    },
  },
  horizontalItemSub: {
    inactive: {
      fontSize: 2,
      padding: 2,
      fontWeight: 'body',
      color: 'text',
      ...aHoverUnderline,
    },
    active: {
      fontSize: 2,
      padding: 2,
      fontWeight: 'bold',
      color: 'text',
      ...aHoverUnderline,
    },
  },
  verticalItem: {
    inactive: {
      py: 2,
      fontWeight: 'body',
      color: 'text',
      ...aHoverUnderline,
    },
    active: {
      py: 2,
      fontWeight: 'bold',
      color: 'text',
    },
  },
  verticalItemSub: {
    inactive: {
      fontSize: 2,
      marginLeft: 3,
      py: 2,
      fontWeight: 'body',
      color: 'text',
      ...aHoverUnderline,
    },
    active: {
      fontSize: 2,
      marginLeft: 3,
      py: 2,
      fontWeight: 'bold',
      color: 'text',
      ...aHoverUnderline,
    },
  },
  verticalOverlayItem: {
    inactive: {
      py: 2,
      fontWeight: 'body',
      color: 'text',
      ...aHoverUnderline,
    },
    active: {
      py: 2,
      fontWeight: 'bold',
      color: 'text',
    },
  },
  verticalOverlayItemSub: {
    inactive: {
      fontSize: 2,
      marginLeft: 3,
      py: 2,
      fontWeight: 'body',
      color: 'text',
      ...aHoverUnderline,
    },
    active: {
      fontSize: 2,
      marginLeft: 3,
      py: 2,
      fontWeight: 'bold',
      color: 'text',
      ...aHoverUnderline,
    },
  },
};
